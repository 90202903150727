import ContactPage from 'components/pages/contactPage'
import Head from 'components/head'
import Layout from 'layouts'
import React from 'react'
import { metaTagData } from 'config'

const Contact = (): React.ReactElement => {
  return (
    <>
      <Head metaTagData={metaTagData} />
      <Layout>
        <ContactPage />
      </Layout>
    </>
  )
}

export default Contact
