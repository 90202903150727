import React from 'react'
import cn from 'classnames'
import { css } from '@emotion/core'
import styleConfig from 'config/styleConfig'
import styled from '@emotion/styled'

const MastHead = styled.div`
  display: flex;
  background: #000;
  flex-direction: column;
  justify-content: center;
  background-size: 100% 100%;
  background-position: bottom left;
  background-repeat: no-repeat;
  overflow: hidden;
  width: 100%;
  height: 350px;
  padding: 40px 0 0 0;
  ${styleConfig.breakPoints.lg} {
    padding: 80px 0 0 0;
  }
  &.largeMode {
    height: 400px;
  }
  ${styleConfig.breakPoints.lg} {
    padding: 30px 0 0 0;
    height: 300px;
    &.largeMode {
      height: 500px;
    }
  }
  position: relative;
`

const MastHeadOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #2a2d36;
  opacity: 0.4;
`
interface IProps {
  largeMode?: boolean
  backgroundImage: string
  children: React.ReactNode
}

const index = ({ largeMode = false, children, backgroundImage }: IProps): React.ReactElement => {
  return (
    <div>
      <MastHead className={cn('text-white font-light animated fadeIn', { largeMode })} css={css`background-image: url('${backgroundImage}');`}>
        {largeMode && <MastHeadOverlay />}
        <div className="relative mx-auto px-4 justify-center container overflow-hidden flex flex-col text-center xl:text-left">{children}</div>
      </MastHead>
    </div>
  )
}

export default index
