import React from 'react'

export const PageContent = (props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>): React.ReactElement => {
  const { children, ...restProps } = props
  return (
    <div key={props.key ? props.key : undefined} {...restProps} className={`mx-auto container flex flex-col flex-grow ${props.className}`}>
      {children}
    </div>
  )
}

export default PageContent
