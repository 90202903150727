import ButtonLink from 'components/common/buttons/button'
import HaveProject from 'components/haveProject'
import LeafletMap from 'components/pages/contactPage/Leaflet'
import Masthead from 'components/mastHead/index.tsx'
import PageContent from 'components/pageContent'
import React from 'react'
import { SiteIcons } from 'components/icons/SiteIcon'
import img from './masthead.jpg'
import { isWebBrowser } from 'utils'

const ContactPage = (): React.ReactElement => {
  /*const handleMapWindow = (): void => {
    window.open(
      'https://www.google.ca/maps/place/Alwind+Industries+Ltd/@43.8263044,-79.3383644,17z/data=!4m12!1m6!3m5!1s0x89d4d481b6378e21:0x40e9ad09479a5529!2sAlwind+Industries+Ltd!8m2!3d43.826494!4d-79.338336!3m4!1s0x89d4d481b6378e21:0x40e9ad09479a5529!8m2!3d43.826494!4d-79.338336?hl=en'
    )
  }*/
  return (
    <>
      <Masthead backgroundImage={img}>
        <h1 className="lg:text-6xl text-5xl text-center">Contact Us</h1>
      </Masthead>
      <PageContent className="py-10 lg:py-20 px-4">
        <h4 className="text-lg lg:text-xl">
          We are located just minutes north of Toronto. Come visit us at{' '}
          <a
            href="https://www.google.com/maps/place/69+Roy+Harper+Ave,+Aurora,+ON+L4G+7C4/@44.0324322,-79.4366317,15z/data=!4m5!3m4!1s0x882ad252f10ed1a9:0xf80da0eeffdc1714!8m2!3d44.0247725!4d-79.4347653"
            rel="noopener noreferrer"
            target="_blank"
          >
            69 Roy Harper Ave. Aurora Ontario L4G-0V5
          </a>
        </h4>
        <div>
          <ul className="flex flex-col lg:flex-row text-center font-medium">
            <li className="flex-grow lg:p-2 pt-2">
              <ButtonLink href="tel://1-416-230-3960" className="lift">
                <SiteIcons.PhoneCall width={20} title="Phone" />
                &nbsp; 1 (416) 230-3960
              </ButtonLink>
            </li>
            <li className="flex-grow lg:p-2 pt-2">
              <ButtonLink href="mailto:rt@matrixdigital.com" className="lift">
                <SiteIcons.Mail width={20} title="Email" />
                &nbsp; rt@matrixdigital.com
              </ButtonLink>
            </li>
          </ul>
        </div>
        <div className="flex-0 bg-white w-128 rounded shadow-md flex card text-grey-darkest flex relative"></div>
        <div className="mt-5 rounded-lg shadow-md overflow-hidden">{isWebBrowser() && <LeafletMap />}</div>
      </PageContent>
      <HaveProject />
    </>
  )
}

export default ContactPage
