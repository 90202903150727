import React from 'react'

interface IProps {
  children: React.ReactNode
}

const ButtonLink = (props: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>): React.ReactElement => {
  const { children, className, ...restProps } = props
  return (
    <a
      className={
        'block w-full items-center px-4 py-4 border border-gray-300 leading-5 font-medium rounded-md bg-white hover:text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 transition duration-150 ease-in-out' +
        ` ${className}`
      }
      {...restProps}
    >
      {children}
    </a>
  )
}

export default ButtonLink
