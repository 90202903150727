import { Map, Marker, Popup, TileLayer } from 'react-leaflet'

import { Icon } from 'leaflet'
import React from 'react'
import { isWebBrowser } from 'utils'
import styled from '@emotion/styled'

const zoom = 11
const position: [number, number] = [44.0248, -79.4348]

const MapContainer = styled.div`
  .iconleaflet {
    color: yellow;
    fill: blue;
  }
  .leaflet-container {
    width: 100%;
    height: 300px;
  }
`

const MyMarker = (): React.ReactElement => {
  const icon = new Icon({
    iconUrl: '/matrix-logo.svg',
    iconSize: [40, 40],
    className: 'iconleaflet',
  })
  return (
    <Marker position={position} icon={icon}>
      <Popup>
        Matrix Digital Solutions Inc.
        <br />
        69 Roy Harper Ave.
        <br />
        Aurora Ontario
        <br />
        L4G-0V5
      </Popup>
    </Marker>
  )
}
//https://blog.logrocket.com/how-to-use-react-leaflet/
const MyMap = (): React.ReactElement | null => {
  if (!isWebBrowser()) {
    return null
  }
  return (
    <MapContainer>
      <Map center={position} zoom={zoom}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' />
        <MyMarker />
      </Map>
    </MapContainer>
  )
}

const LeafletMap = (): React.ReactElement => <MyMap />

export default LeafletMap
