import { Link } from 'gatsby'
import React from 'react'
//import ScrollAnimation from 'react-animate-on-scroll'
import { sitePaths } from 'config/paths'
import { styleConfig } from 'config/styleConfig'
import styled from '@emotion/styled'

const HaveProjectContainer = styled.div`
  background-color: ${styleConfig.colors.gray[600]};
  padding: 3em 0;
  @media screen and (min-width: ${styleConfig.screens.lg}) {
    padding: 4em 0;
  }
  h2 {
    font-weight: 300;
    color: white;
    font-size: 2em;
    @media screen and (min-width: ${styleConfig.screens.lg}) {
      font-size: 2.5em;
    }
  }
`

const HaveProject = (): React.ReactElement => {
  return (
    <HaveProjectContainer>
      <div data-aos="fade-up" className="flex flex-col mx-4 items-center">
        <h2>Have a project?</h2>
        <Link data-aos="fade-down" data-aos-delay="300" to={sitePaths.appLinks.contact} className="max-w-xl w-100 lift px-5 btn">
          Let{"'"}s get started!
        </Link>
      </div>
    </HaveProjectContainer>
  )
}

export default HaveProject
